@import "src/styles/helpers";

.details {
  counter-reset: details;
  padding-top: var(--margin-xxl);
  padding-bottom: var(--margin-xxl);

  @include media(tablet) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  @include media(desktopAll) {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  &__content {
    margin-top: 56px;
    display: grid;
    gap: 48px;

    @include media(tablet) {
      gap: var(--margin-xl);
    }

    @include media(landscape) {
      margin-top: 92px;
    }

    @include media(desktopAll) {
      margin-top: 92px;
      gap: var(--margin-xl);
    }
  }
}

.title {
  font-size: 28px;
  line-height: 36px;

  @include media(landscape) {
    font-size: 40px;
    line-height: 48px;
  }

  @include media(desktopAll) {
    font-size: 40px;
    line-height: 48px;
  }
}

.item {
  counter-increment: details;
  display: grid;
  justify-content: space-between;
  gap: 48px;
  grid-template-columns: 50px auto;
  padding-bottom: 40px;
  border-bottom: 1px solid;
  @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));

  @include media(portrait) {
    grid-template-columns: 50px 395px;
  }

  @include media(landscape) {
    grid-template-columns: 71px 461px;
  }

  @include media(desktopAll) {
    grid-template-columns: 71px 621px;
  }

  @include media(desktopBig) {
    grid-template-columns: 71px 861px;
  }

  @include media(desktopHuge) {
    grid-template-columns: 71px 861px;
  }

  &:nth-child(even) {
    grid-template-columns: auto 50px;

    @include media(portrait) {
      grid-template-columns: 350px 71px;
    }

    @include media(landscape) {
      grid-template-columns: 461px 71px;
    }

    @include media(desktopAll) {
      grid-template-columns: 621px 71px;
    }

    @include media(desktopBig) {
      grid-template-columns: 861px 71px;
    }

    @include media(desktopHuge) {
      grid-template-columns: 861px 71px;
    }

    .item {

      &__counter {
        text-align: right;
        order: 2;
      }
    }
  }

  &__counter {
    width: 50px;

    &:before {
      content: counter(details, decimal-leading-zero);
      font-family: 'KharkivTone', sans-serif;
      font-size: 32px;
      line-height: 40px;
      @include setProperty(color, var(--secondary-500), var(--secondary-100));

      @include media(landscape) {
        font-size: 48px;
        line-height: 56px;
      }

      @include media(desktopAll) {
        font-size: 48px;
        line-height: 56px;
      }
    }
  }

  &__title {
    font-family: 'KharkivTone', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: var(--margin-sm);

    @include media(landscape) {
      font-size: 20px;
      line-height: 28px;
    }

    @include media(desktopAll) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 2;
    font-weight: 300;

    @include media(tablet) {
      font-size: 16px;
      letter-spacing: var(--ls-183);
    }

    @include media(desktopAll) {
      font-size: 16px;
      letter-spacing: var(--ls-183);
    }
  }
}